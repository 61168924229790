import '../styles/widgets.css'

function StandardCloseButton({onClick, hideText}) {
    return(
        <button className='standard-close-btn-container'
            onClick={onClick} >
            <img 
            className='standard-close-btn-icon-img'
            src='/assets/images/standard-icons/close-icon.png' />
            {
                hideText && <div className='text-label-xl standard-close-btn-text'>Close</div>
            }
            
        </button>
    )
}

export default StandardCloseButton;