import { TypeAnimation } from 'react-type-animation';

function LoadingIndicator() {
    return (
        <TypeAnimation
            sequence={[
                // Same substring at the start will only be typed out once, initially
                'Loading',
                0, // wait 1s before replacing "Mice" with "Hamsters"
                'Loading.',
                500,
                'Loading..',
                500,
                'Loading...',
                500
            ]}
            className='text-primary2-ml'
            style={{color: 'var(--color-primary-1'}}
            speed={25}
            repeat={Infinity}
            cursor={false}
            omitDeletionAnimation={true}
        />
    );
}

export default LoadingIndicator;