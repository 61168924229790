import { useEffect, useState } from 'react';
import './shop-item-feature-screen.css';
import StandardFormTextfield from '../../widgets/StandardFormTextfield';
import PrimaryLongButton from '../../widgets/PrimaryLongButton';
import BackToHomeButton from '../../widgets/BackToHomeButton';
import StandardCloseButton from '../../widgets/StandardCloseButton';
import { useMediaQuery } from 'react-responsive';
import ShopItemRequestService from '../../features/ShopItemRequest/ShopItemRequestService';
import ShopItemRequest from '../../features/ShopItemRequest/ShopItemRequest';
import { useSwipeable } from 'react-swipeable';
import LoadingIndicator from '../../component/LoadingIndicator/LoadingIndicator';

function ShopItemFeatureScreen({backCallback}) {
    const [showFeaturePopover, setShowShopItemFeaturePopover] = useState(false);
    const [showShopItemFeatureImages, setShowShopItemFeatureImages] = useState(false);
    const [showFormSentSuccessfully, setShowFormSentSuccessfully] = useState(false);
    const [showFormLoading, setShowFormLoading] = useState(false);
    const [currentFeatureImageIndex, setCurrentFeatureImageIndex] = useState(0);
    const [didSelectPopover, setDidSelectPopover] = useState(false);

    const [itemName, setItemName] = useState('');
    const [customMessage, setCustomMessage] = useState('');

    const isMobile = useMediaQuery({query: '(max-width: 600px'});
    const isDesktop = useMediaQuery({query: '(min-width: 600px'});
    const shopItemFeatureImageCount = 3;

    const handleSendNewRequest = async () => {
        setShowFormLoading(true);
        await ShopItemRequestService.createNewShopItemRequest(
            new ShopItemRequest(itemName, customMessage)
        );
        setShowFormLoading(false);
        setShowFormSentSuccessfully(true);
    };

    function resetNewRequestForm() {
        setItemName('');
        setCustomMessage('');
        setShowFormSentSuccessfully(false);
    }

    function handleOverlaySwipedRight() {
        if (currentFeatureImageIndex > 0) {
            setCurrentFeatureImageIndex(currentFeatureImageIndex - 1);
        }
    }

    function handleOverlaySwipedLeft() {
        if (currentFeatureImageIndex < shopItemFeatureImageCount - 1) {
            setCurrentFeatureImageIndex(currentFeatureImageIndex + 1);
        }
    }
    const swipeHandlers = useSwipeable({
        onSwipedLeft: handleOverlaySwipedLeft,
        onSwipedRight: handleOverlaySwipedRight,
        preventScrollOnSwipe: true,
    })

    useEffect(() => {
        setShowShopItemFeatureImages(showFeaturePopover);
    }, [showFeaturePopover]);

    return (
        <div className='shop-item-feature-screen-root-container'>
            <img className='shop-item-feature-screen-bg-image'
                src="assets/images/backgrounds/bg-day-1.png" />
            {
                showFeaturePopover === false && isDesktop &&
                <BackToHomeButton onClick={ backCallback } />
            }
            <div className='shop-item-feature-screen-container'>
                {
                    isMobile && 
                    <div className='text-primary2-ml shop-item-feature-page-desc-text'>Find pop-ups that have handmade versions of items you need!</div>
                }
                <img src="assets/images/shop-item-feature/shop-item-highlight.png" 
                    className='shop-item-feature-highlight-img'
                    onClick={() =>{
                        setShowShopItemFeaturePopover(true)
                        setDidSelectPopover(true)
                    }}
                    style={{
                        transform: 'scale(1.3)',
                        animation: didSelectPopover ? 'initial' : 'pulse 2s infinite'
                    }}
                    />
                {
                    !showFormSentSuccessfully && !showFormLoading &&
                    <div className='shop-item-feature-req-form-container'>
                        <div className='shop-item-feature-req-form-heading-container'>
                            <div className='text-primary2-ml shop-item-feature-req-form-heading'>
                                Want something handmade?</div>
                            <div className='text-secondary-m shop-item-feature-req-form-subheading'>
                            We’ll look for artists that make these!</div>
                            <div className='shop-item-feature-req-form-input-section-container'>
                                <StandardFormTextfield  
                                label={'Item Name'}
                                onChange={(newText) => setItemName(newText)}
                                />
                                <StandardFormTextfield  
                                label={'Any other notes?'}
                                height={'10vh'}
                                onChange={(newText) => setCustomMessage(newText)}
                                />
                            </div>
                            <div className='shop-item-feature-req-form-send-btn'>
                                <PrimaryLongButton 
                                title={'Send'} 
                                width={isMobile ? '85vw' : '28vw'}
                                onClick={handleSendNewRequest}
                                />
                            </div>
                        </div>
                    </div>
                }
                {
                    showFormLoading &&
                    <div className='shop-item-feature-req-sent-successfully-container'>
                        <LoadingIndicator />
                    </div>
                }
                {
                    showFormSentSuccessfully && 
                    <div className='shop-item-feature-req-sent-successfully-container'>
                        <img 
                            className='shop-item-feature-req-sent-successfully-img'
                            src='/assets/images/general/thank-you-card.png' />
                        <div className='shop-item-feature-submit-new-request-btn'>
                            <PrimaryLongButton 
                                title = '+ Submit New Request'
                                width = {isMobile ? '60vw' : '25vw'}
                                onClick = { resetNewRequestForm }
                             />
                        </div>
                            
                    </div>
                
                }
               
            </div>
            <div className='popover-container'
            style={{
                opacity: showFeaturePopover ? '1' : '0', 
                transition: 'opacity 0.25s ease-in'
            }}>
                <div className='popover-overlay-background' {...swipeHandlers}>
                <div className='shop-item-feature-gallery-close-btn-container'>
                    <StandardCloseButton 
                        onClick={ () => {
                            setShowShopItemFeaturePopover(false);
                            setCurrentFeatureImageIndex(0);
                        } } 
                        hidesText={ true }
                    />
                </div>
                <div className='shop-item-feature-gallery-container'>
                    <div className='shop-item-feature-gallery-item-container'
                        style= { isMobile ? {
                            transform: `translateX(${0 - (currentFeatureImageIndex * 100)}vw)`
                        } : { zIndex: '2' }}>
                        <img className='shop-item-feature-gallery-item-img'
                            src='/assets/images/shop-item-feature/shop-item-feature-search.png'/>
                        <div className='text-label-l shop-item-feature-gallery-item-desc'
                            style={{
                                opacity: showShopItemFeatureImages ? '1' : '0',
                                transition: 'opacity 0.25s linear',
                                transitionDelay: '0.25s',
                            }}>Find a handmade version of any item</div>
                    </div>
                    <div className='shop-item-feature-gallery-item-container'
                        style={ isMobile ? { 
                            transform: `translateX(${100 - (currentFeatureImageIndex * 100)}vw)`
                        } : {
                            left: showShopItemFeatureImages ? '33vw' : '0vw',
                            transition: '0.5s ease-out',
                            transitionDelay: '1s',
                            zIndex: '1',
                            position: 'absolute'
                        }}>
                        <img className='shop-item-feature-gallery-item-img'
                            src='/assets/images/shop-item-feature/shop-item-feature-spotlight.png'/>
                        <div className='text-label-l shop-item-feature-gallery-item-desc'
                            style={{
                                opacity: showShopItemFeatureImages ? '1' : '0',
                                transition: 'opacity 0.25s linear',
                                transitionDelay: '1.5s',
                            }}>Get inspired by new gifts</div>
                    </div>
                    <div className='shop-item-feature-gallery-item-container'
                        style={ isMobile ? { 
                            transform: `translateX(${200 - (currentFeatureImageIndex * 100)}vw)`
                        } : {
                            left: showShopItemFeatureImages ? '66vw' : '0vw',
                            transition: '0.75s linear',
                            transitionDelay: '2.75s',
                            zIndex: '0',
                            position: 'absolute'
                        }}>
                        <img className='shop-item-feature-gallery-item-img'
                            src='/assets/images/shop-item-feature/shop-item-feature-pickup.png'/>
                        <div className='text-label-l shop-item-feature-gallery-item-desc'
                            style={{
                                opacity: showShopItemFeatureImages ? '1' : '0',
                                transition: 'opacity 0.25s linear',
                                transitionDelay: '4s',
                            }}>Let it be your reason to come out!</div>
                    </div>
                </div>
            </div>

            </div>

            {
                showFeaturePopover === true && isMobile &&
                // controls
                <div className='shop-item-feature-popover-controls-container'>
                    {
                        currentFeatureImageIndex > 0 &&
                        <button onClick={ () => setCurrentFeatureImageIndex(currentFeatureImageIndex - 1)}
                        className='shop-item-feature-popover-controls-prev-btn'>
                            <img src='/assets/images/standard-icons/chevron-left.png'
                                className='shop-item-feature-popover-controls-prev-img' />
                        </button>
                    }
                    {
                        currentFeatureImageIndex < (shopItemFeatureImageCount-1) &&
                        <button onClick={ () => setCurrentFeatureImageIndex(currentFeatureImageIndex + 1)}
                        className='shop-item-feature-popover-controls-next-btn'>
                            <img src='/assets/images/standard-icons/chevron-right.png'
                                className='shop-item-feature-popover-controls-next-img' />
                        </button>
                    }
                    {
                        shopItemFeatureImageCount > 1 && 
                        // pagination
                        <div className='pagination-container'
                            style={{
                                position: 'relative',
                                top: '92.5vh',
                                zIndex: '1000',
                            }}>
                            {
                                Array.from({length: shopItemFeatureImageCount}).map((_, index) => {
                                    return <div className='pagination-circle' 
                                        key={index}
                                        style={{
                                            backgroundColor: currentFeatureImageIndex == index ? 'white' : 'transparent',
                                            transition: '0.25s ease-out'
                                        }}/>
                                })
                            }
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default ShopItemFeatureScreen;