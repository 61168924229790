import { db } from '../../firebase-config.js';
import { collection, addDoc } from 'firebase/firestore';
import NetworkUtility from '../../utilities/NetworkUtility.js';
import { Timestamp } from "@firebase/firestore";


class EventFeatureService {
    static eventFeatureCollection = collection(db, 'eventRequests');

    static createNewEventRequest = async (eventRequest) => {
        const ip = await  NetworkUtility.getIPAddress();
        await addDoc(this.eventFeatureCollection,
            {...eventRequest, 
                'date': Timestamp.fromDate(new Date()),
                'ip': ip,
            });
    }
}

export default EventFeatureService;