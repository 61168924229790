import { db } from '../../firebase-config';
import { collection, addDoc } from "firebase/firestore";
import NetworkUtility from '../../utilities/NetworkUtility.js';
import { Timestamp } from "@firebase/firestore";

class VendorRequestService {
    static vendorRequestCollection = collection(db, 'vendorRequests');

    static createNewVendorRequest = async (vendorRequest) => {
        const ip = await  NetworkUtility.getIPAddress();
        await addDoc(this.vendorRequestCollection, 
            {...vendorRequest,
                'date': Timestamp.fromDate(new Date()),
                'ip': ip,
            });
    }
}

export default VendorRequestService;