import { useState } from 'react';
import MainNavbar from "../component/MainNavbar/MainNavbar";
import HomeScreen from "../screens/HomeScreen/HomeScreen";
import './main.css';
import ContactBar from "../component/ContactBar/ContactBar";
import FooterSection from "../component/FooterSection/FooterSection";
import EventFeatureScreen from '../screens/EventFeatureScreen/EventFeatureScreen';
import VendorFeatureScreen from '../screens/VendorFeatureScreen/VendorFeatureScreen';
import ShopItemFeatureScreen from '../screens/ShopItemFeatureScreen/ShopItemFeatureScreen';
import NavigationRoutes from '../enums/Routes';
import { Route, Routes } from 'react-router-dom';

function Main() {

    return (     
        <div className="main-root-container">
            <div className="main-sections-container">
                <Routes>
                    <Route path={NavigationRoutes.homeScreen} element={<HomeScreen />} />
                    <Route path={NavigationRoutes.eventFeatureScreen} element={ <EventFeatureScreen /> } />
                    <Route path={NavigationRoutes.vendorFeatureScreen} element={ <VendorFeatureScreen /> } />
                    <Route path={NavigationRoutes.shopItemFeatureScreen} element={ <ShopItemFeatureScreen /> } />
                </Routes>
                <FooterSection />
            </div>
            <MainNavbar />
            <ContactBar />
        </div>
    )
}

export default Main;