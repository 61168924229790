import './contact-bar.css'

function ContactBar() {
    return (
        <div className='contact-bar-root-container'>
            {/* <img className='contact-bar-bg-img'
            src='/assets/images/material-backgrounds/wooden-board.png' /> */}
            <div className='contact-icons-container'>
                <a href='https://www.instagram.com/forvilla_project' target='_blank'>
                    <img className='contact-icon-img' 
                    src='/assets/images/icons/ig-icon.png'/>
                </a>
                <a href='https://www.facebook.com/profile.php?id=100086501281875' target='_blank'>
                    <img className='contact-icon-img' 
                    src='/assets/images/icons/fb-icon.png'/>
                </a>
                <a href='https://apps.apple.com/us/app/forvilla/id6444273771' target='_blank'>
                    <img className='contact-icon-img' 
                    src='/assets/images/icons/apple-icon.png'/>
                </a>
                <a href='https://aqm55lzx2o4.typeform.com/to/AqHjOD3i' target='_blank'>
                    <img className='contact-icon-img' 
                    src='/assets/images/icons/android-icon.png'/>
                </a>
                <a href='mailto:forvilla@community.org'>
                    <img className='contact-icon-img' 
                    src='/assets/images/icons/email-icon.png'/>
                </a>
            </div>
        </div>
    )
}

export default ContactBar;