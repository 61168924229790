import { useState, useEffect } from 'react';
import './event-feature-screen.css';
import StandardFormTextfield from '../../widgets/StandardFormTextfield';
import PrimaryLongButton from '../../widgets/PrimaryLongButton';
import BackToHomeButton from '../../widgets/BackToHomeButton';
import StandardCloseButton from '../../widgets/StandardCloseButton';
import { useMediaQuery } from 'react-responsive';
import EventFeatureService from '../../features/EventRequest/EventRequestService.js';
import EventRequest from '../../features/EventRequest/EventRequest.js';
import { useSwipeable } from 'react-swipeable';
import LoadingIndicator from '../../component/LoadingIndicator/LoadingIndicator';

function EventFeatureScreen({backCallback}) {
    const [showFeaturePopover, setShowEventFeaturePopover] = useState(false);
    const [showFeatureImages, setShowEventFeatureImages] = useState(false);
    const [showFormSentSuccessfully, setShowFormSentSuccessfully] = useState(false);
    const [showFormLoading, setShowFormLoading] = useState(false);
    const [currentFeatureImageIndex, setCurrentFeatureImageIndex] = useState(0);
    const [didSelectPopover, setDidSelectPopover] = useState(false);

    const [marketName, setMarketName] = useState("");
    const [marketLocation, setMarketLocation] = useState("");
    const [customMessage, setCustomMessage] = useState("");

    const isMobile = useMediaQuery({query: '(max-width: 600px'});
    const isDesktop = useMediaQuery({query: '(min-width: 600px'});
    const eventFeatureImageCount = 4;

    const handleSendNewRequest = async () => {
        setShowFormLoading(true);
        await EventFeatureService.createNewEventRequest(
            new EventRequest(marketName, marketLocation, customMessage)
        );
        setShowFormLoading(false);
        setShowFormSentSuccessfully(true);
    }

    function resetNewRequestForm() {
        setMarketName('');
        setMarketLocation('');
        setCustomMessage('');
        setShowFormSentSuccessfully(false);
    }

    function handleOverlaySwipedRight() {
        if (currentFeatureImageIndex > 0) {
            setCurrentFeatureImageIndex(currentFeatureImageIndex - 1);
        }
    }

    function handleOverlaySwipedLeft() {
        if (currentFeatureImageIndex < eventFeatureImageCount - 1) {
            setCurrentFeatureImageIndex(currentFeatureImageIndex + 1);
        }
    }
    const swipeHandlers = useSwipeable({
        onSwipedLeft: handleOverlaySwipedLeft,
        onSwipedRight: handleOverlaySwipedRight,
        preventScrollOnSwipe: true,
    })

    useEffect(() => {
        if (showFeaturePopover) {
            setShowEventFeatureImages(true);
        }
        else {
            setShowEventFeatureImages(false);
        }
    }, [showFeaturePopover]);

    return (
        <div className='event-feature-screen-root-container'>
            <img className='event-feature-screen-bg-image'
                src="assets/images/backgrounds/bg-day-1.png" />
            { showFeaturePopover === false && isDesktop &&
                <BackToHomeButton onClick={ backCallback } />
            }
            <div className='event-feature-screen-container'>
                {
                    isMobile && 
                    <div className='text-primary2-ml event-feature-page-desc-text'>Find nearby pop-ups that fit your schedule!</div>
                }
                <img src="assets/images/event-feature/event-feature-highlight.png" 
                    onClick={() => {
                        setShowEventFeaturePopover(true)
                        setDidSelectPopover(true)
                    }}
                    className='event-feature-highlight-img'
                    style={{animation: didSelectPopover ? '0s' : 'pulse 2s infinite'}}
                    />
                {
                    isMobile &&
                    <div className='text-label-ml event-feature-page-more-info-text'>Tap to learn more</div>
                }
                {
                !showFormSentSuccessfully && !showFormLoading &&
                    <div className='event-feature-req-form-container'>
                    <div className='event-feature-req-form-heading-container'>
                        <div className='text-primary2-l event-feature-req-form-heading'>
                            Know of a pop-up?</div>
                        <div className='text-secondary-m event-feature-req-form-subheading'>
                        You can contribute by letting us know! We’ll reach out to the market organizers.</div>
                        <div className='event-feature-req-form-input-section-container'>
                            <StandardFormTextfield  
                            label={'Market Name'}
                            onChange={(newText) => setMarketName(newText)}/>
                            <StandardFormTextfield  
                            label={'Market Location'}
                            onChange={(newText) => setMarketLocation(newText)}/>
                            <StandardFormTextfield  
                            label={'Any other notes?'}
                            height={'10vh'}
                            onChange={(newText) => setCustomMessage(newText)}/>
                        </div>
                        <div className='event-feature-req-form-send-btn'>
                            <PrimaryLongButton 
                            title={'Send'} 
                            width={isMobile ? '100%' : '28vw'}
                            onClick={()=>handleSendNewRequest()}
                            />
                        </div>
                    </div>
                </div>
                }
                {
                    showFormLoading &&
                    <div className='event-feature-req-sent-successfully-container'>
                        <LoadingIndicator />
                    </div>
                }
                {
                    showFormSentSuccessfully && 
                    <div className='event-feature-req-sent-successfully-container'>
                        <img 
                            className='event-feature-req-sent-successfully-img'
                            src='/assets/images/general/thank-you-card.png' />
                        <div className='event-feature-submit-new-request-btn'>
                            <PrimaryLongButton 
                                title = '+ Submit New Request'
                                width = {isMobile ? '60vw' : '25vw'}
                                onClick = { resetNewRequestForm }
                             />
                        </div>
                            
                    </div>
                
                }
                
               
            </div>

            <div className='popover-container'
            style={{
                opacity: showFeaturePopover ? '1' : '0', 
                transition: 'opacity 0.25s ease-in'
            }}>
                 <div className='popover-overlay-background' {...swipeHandlers}>
                    <div className='event-feature-gallery-close-btn-container'>
                        <StandardCloseButton 
                            onClick={ () => {
                                setShowEventFeaturePopover(false);
                                setCurrentFeatureImageIndex(0);
                            } } 
                            hidesText={true}
                        />
                    </div>
                    <div className='event-feature-gallery-container'>
                        <div className='event-feature-gallery-item-container'
                            style= {isMobile ? {
                                transform: `translateX(${0 - (currentFeatureImageIndex * 100)}vw)`
                            } :
                            { zIndex: '3' }}>
                            <img className='event-feature-gallery-item-img'
                                src='/assets/images/event-feature/events-by-location.png'/>
                            <div className='text-label-l event-feature-gallery-item-desc'>Find pop-ups near you</div>
                        </div>
                        <div className='event-feature-gallery-item-container'
                            style={isMobile ? {
                                transform: `translateX(${100 - (currentFeatureImageIndex * 100)}vw)`
                            } : {
                                left: showFeatureImages ? '25vw' : '0vw',
                                transition: '0.5s ease-out',
                                transitionDelay: '1s',
                                zIndex: '2',
                                position: 'absolute'
                            }}>
                            <img className='event-feature-gallery-item-img'
                                src='/assets/images/event-feature/events-by-date.png'/>
                            <div className='text-label-l event-feature-gallery-item-desc'
                                style={isMobile ? {} : {
                                    opacity: showFeatureImages ? '1' : '0',
                                    transition: 'opacity 0.25s linear',
                                    transitionDelay: '1.5s',
                                }}>Find pop-ups that fit your schedule</div>
                        </div>
                        <div className='event-feature-gallery-item-container'
                            style={isMobile ? {
                                transform: `translateX(${200 - (currentFeatureImageIndex * 100)}vw)`
                            } : {
                                left: showFeatureImages ? '50vw' : '0vw',
                                transition: '0.75s linear',
                                transitionDelay: '2.5s',
                                zIndex: '1',
                                position: 'absolute'
                            }}>
                            <img className='event-feature-gallery-item-img'
                                src='/assets/images/event-feature/events-detail.png'/>
                            <div className='text-label-l event-feature-gallery-item-desc'
                                style={isMobile ? {} : {
                                    opacity: showFeatureImages ? '1' : '0',
                                    transition: '0.75s linear',
                                    transitionDelay: '3.5s',
                                }}>Get updated event details</div>
                        </div>

                        <div className='event-feature-gallery-item-container'
                            style={isMobile ? {
                                transform: `translateX(${300 - (currentFeatureImageIndex * 100)}vw)`
                            } : {
                                left: showFeatureImages ? '75vw' : '0vw',
                                transition: '1s linear',
                                transitionDelay: '4.5s',
                                zIndex: '0',
                                position: 'absolute'
                            }}>
                            <img className='event-feature-gallery-item-img'
                                src='/assets/images/event-feature/events-vendor-list.png'/>
                            <div className='text-label-l event-feature-gallery-item-desc'
                            style={isMobile ? {} : {
                                opacity: showFeatureImages ? '1' : '0',
                                transition: '0.75s linear',
                                transitionDelay: '5.5s',
                            }}>See who you’ll meet!</div>
                        </div>
                    </div>
                </div>
            </div>
            
            {
                showFeaturePopover === true && isMobile &&
                // controls
                <div className='event-feature-popover-controls-container'>
                    {
                        currentFeatureImageIndex > 0 &&
                        <button onClick={ () => setCurrentFeatureImageIndex(currentFeatureImageIndex - 1)}
                        className='event-feature-popover-controls-prev-btn'
                        >
                            <img src='/assets/images/standard-icons/chevron-left.png'
                                className='event-feature-popover-controls-prev-img' 
                            />
                        </button>
                    }
                    {
                        currentFeatureImageIndex < (eventFeatureImageCount-1) &&
                        <button onClick={ () => setCurrentFeatureImageIndex(currentFeatureImageIndex + 1)}
                        className='event-feature-popover-controls-next-btn'>
                            <img src='/assets/images/standard-icons/chevron-right.png'
                                className='event-feature-popover-controls-next-img' 
                            />
                        </button>
                    }
                    {
                        eventFeatureImageCount > 1 && 
                        // pagination
                        <div className='pagination-container'
                            style={{
                                position: 'relative',
                                top: '92.5vh',
                                zIndex: '1000',
                            }}>
                            {
                                Array.from({length: eventFeatureImageCount}).map((_, index) => {
                                    return <div className='pagination-circle' 
                                        key={index}
                                        style={{
                                            backgroundColor: currentFeatureImageIndex == index ? 'white' : 'transparent',
                                            transition: '0.25s ease-out'
                                        }}/>
                                })
                            }
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default EventFeatureScreen;