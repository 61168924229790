import '../styles/widgets.css'
import { useNavigate } from 'react-router-dom';

function BackToHomeButton() {
    const navigateTo = useNavigate();
    return (
        <button className='back-to-home-btn-container'
            onClick={() => navigateTo('/')} >
            <img className='back-to-home-icon-img'
            src='/assets/images/standard-icons/chevron-left.png' />
            <div className='text-primary2-m back-to-home-btn-title'>
                Back to home
            </div>
        </button>
    )
}

export default BackToHomeButton;