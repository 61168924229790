import { useEffect, useState } from 'react';
import './vendor-feature-screen.css';
import StandardFormTextfield from '../../widgets/StandardFormTextfield';
import PrimaryLongButton from '../../widgets/PrimaryLongButton';
import BackToHomeButton from '../../widgets/BackToHomeButton';
import StandardCloseButton from '../../widgets/StandardCloseButton';
import { useMediaQuery } from 'react-responsive';
import VendorRequestService from '../../features/VendorRequest/VendorRequestService';
import VendorRequest from '../../features/VendorRequest/VendorRequest';
import { useSwipeable } from 'react-swipeable';
import LoadingIndicator from '../../component/LoadingIndicator/LoadingIndicator';

function VendorFeatureScreen({backCallback}) {
    const [showFeaturePopover, setShowVendorFeaturePopover] = useState(false);
    const [showVendorFeatureImages, setShowVendorFeatureImages] = useState(false);
    const [showFormSentSuccessfully, setShowFormSentSuccessfully] = useState(false);
    const [showFormLoading, setShowFormLoading] = useState(false);
    const [currentFeatureImageIndex, setCurrentFeatureImageIndex] = useState(0);
    const [didSelectPopover, setDidSelectPopover] = useState(false);

    const [name, setName] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [customMessage, setCustomMessage] = useState('');

    const isMobile = useMediaQuery({query: '(max-width: 600px'});
    const isDesktop = useMediaQuery({query: '(min-width: 600px'});
    const vendorFeatureImageCount = 4;

    const handleSendNewRequest = async () => {
        setShowFormLoading(true);
        await VendorRequestService.createNewVendorRequest(
            new VendorRequest(name, businessName, customMessage)
        );
        setShowFormLoading(false);
        setShowFormSentSuccessfully(true);
    }

    function handleSubmitNewRequest() {
        setName('');
        setBusinessName('');
        setCustomMessage('');
        setShowFormSentSuccessfully(false);
    }

    function handleOverlaySwipedRight() {
        if (currentFeatureImageIndex > 0) {
            setCurrentFeatureImageIndex(currentFeatureImageIndex - 1);
        }
    }

    function handleOverlaySwipedLeft() {
        if (currentFeatureImageIndex < vendorFeatureImageCount - 1) {
            setCurrentFeatureImageIndex(currentFeatureImageIndex + 1);
        }
    }
    const swipeHandlers = useSwipeable({
        onSwipedLeft: handleOverlaySwipedLeft,
        onSwipedRight: handleOverlaySwipedRight,
        preventScrollOnSwipe: true,
    })

    useEffect(() => {
        setShowVendorFeatureImages(showFeaturePopover);
    }, [showFeaturePopover])

    return (
        <div className='vendor-feature-screen-root-container'>
            <img className='vendor-feature-screen-bg-image'
                src="assets/images/backgrounds/bg-day-1.png" />
            { showFeaturePopover === false && isDesktop &&
                <BackToHomeButton onClick={ backCallback } />
            }
            <div className='vendor-feature-screen-container'>
                {
                    isMobile && 
                    <div className='text-primary2-ml vendor-feature-page-desc-text'>Find pop-ups by following your favorite handmade artisans!</div>
                }
                <img src="assets/images/vendor-feature/vendor-feature-highlight.png" 
                    className='vendor-feature-highlight-img'
                    onClick={() => {
                        setShowVendorFeaturePopover(true)
                        setDidSelectPopover(true)
                    }}
                    style={{animation: didSelectPopover ? '0s' : 'pulse 2s infinite'}}
                    />
                {/* {
                    isMobile &&
                    <div className='text-label-ml vendor-feature-page-more-info-text'>Tap to learn more</div>
                } */}
                { !showFormSentSuccessfully && !showFormLoading &&
                    <div className='vendor-feature-req-form-container'>
                        <div className='vendor-feature-req-form-heading-container'>
                            <div className='text-primary2-ml vendor-feature-req-form-heading'>
                            Know of a handmade seller?</div>
                            <div className='text-secondary-m vendor-feature-req-form-subheading'>
                            We’ll ask them to join Forvilla!</div>
                            <div className='vendor-feature-req-form-input-section-container'>
                                <StandardFormTextfield  
                                label={'Name'}
                                onChange={(newText) => setName(newText)}
                                />
                                <StandardFormTextfield  
                                label={'Business Name'}
                                onChange={(newText) => setBusinessName(newText)}
                                />
                                <StandardFormTextfield  
                                label={'Any other notes?'}
                                height={'10vh'}
                                onChange={(newText) => setCustomMessage(newText)}
                                />
                            </div>
                            <div/>
                            <div className='vendor-feature-req-form-send-btn'>
                                <PrimaryLongButton 
                                title={'Send'} 
                                width={isMobile ? '85vw' : '28vw'}
                                onClick={handleSendNewRequest}
                                />
                            </div>
                        </div>
                    </div>
                }
                {
                    showFormLoading &&
                    <div className='vendor-feature-req-sent-successfully-container'>
                        <LoadingIndicator />
                    </div>
                }
                {
                    showFormSentSuccessfully && 
                    <div className='vendor-feature-req-sent-successfully-container'>
                        <img 
                            className='vendor-feature-req-sent-successfully-img'
                            src='/assets/images/general/thank-you-card.png' />
                        <div className='vendor-feature-submit-new-request-btn'>
                            <PrimaryLongButton 
                                title = '+ Submit New Request'
                                width = {isMobile ? '60vw' : '25vw'}
                                onClick = { handleSubmitNewRequest }
                             />
                        </div>
                            
                    </div>
                
                }
            </div>
           
           <div className='popover-container'
            style={{
                opacity: showFeaturePopover ? '1' : '0', 
                transition: 'opacity 0.25s ease-in'
            }}>
                <div className='popover-overlay-background' {...swipeHandlers}>
                    <div className='vendor-feature-gallery-close-btn-container'>
                        <StandardCloseButton 
                            onClick={ () => {
                                setShowVendorFeaturePopover(false);
                                setCurrentFeatureImageIndex(0);
                            } } 
                            hidesText = {true} 
                        />
                    </div>
                    <div className='vendor-feature-gallery-container'>
                        <div className='vendor-feature-gallery-item-container'
                            style= {isMobile ? {
                                transform: `translateX(${0 - (currentFeatureImageIndex * 100)}vw)`
                            } : 
                            { zIndex: '3' }}>
                            <img className='vendor-feature-gallery-item-img'
                                src='/assets/images/vendor-feature/vendor-feature-discover.png'/>
                            <div className='text-label-l vendor-feature-gallery-item-desc'>Find pop-ups through local makers</div>
                        </div>

                        <div className='vendor-feature-gallery-item-container'
                            style={isMobile ? {
                                    transform: `translateX(${100 - (currentFeatureImageIndex * 100)}vw)`
                                } : {
                                left: showVendorFeatureImages ? '25vw' : '0vw',
                                transition: '0.5s ease-out',
                                transitionDelay: '1s',
                                zIndex: '2',
                                position: 'absolute'
                            }}>
                            <img className='vendor-feature-gallery-item-img'
                                src='/assets/images/vendor-feature/vendor-feature-shop-items.png'/>
                            <div className='text-label-l vendor-feature-gallery-item-desc'
                                style={{
                                    opacity: showVendorFeatureImages ? '1' : '0',
                                    transition: 'opacity 0.25s linear',
                                    transitionDelay: '1.5s',
                                }}>Checkout what items they make</div>
                        </div>
                        <div className='vendor-feature-gallery-item-container'
                            style={isMobile ? {
                                transform: `translateX(${200 - (currentFeatureImageIndex * 100)}vw)`
                                } : {
                                left: showVendorFeatureImages ? '50vw' : '0vw',
                                transition: '0.75s linear',
                                transitionDelay: '2.5s',
                                zIndex: '1',
                                position: 'absolute'
                            }}>
                            <img className='vendor-feature-gallery-item-img'
                                src='/assets/images/vendor-feature/vendor-feature-story.png'/>
                            <div className='text-label-l vendor-feature-gallery-item-desc'
                                style={{
                                    opacity: showVendorFeatureImages ? '1' : '0',
                                    transition: '0.75s linear',
                                    transitionDelay: '3.5s',
                                }}>Read personal artist stories</div>
                        </div>
                        <div className='vendor-feature-gallery-item-container'
                            style={ isMobile ?{
                                transform: `translateX(${300 - (currentFeatureImageIndex * 100)}vw)`
                                } : {
                                left: showVendorFeatureImages ? '75vw' : '0vw',
                                transition: '1s linear',
                                transitionDelay: '4.5s',
                                zIndex: '0',
                                position: 'absolute'
                            }}>
                            <img className='vendor-feature-gallery-item-img'
                                src='/assets/images/vendor-feature/vendor-feature-calendar.png'/>
                            <div className='text-label-l vendor-feature-gallery-item-desc'
                                style={{
                                    opacity: showVendorFeatureImages ? '1' : '0',
                                    transition: '0.75s linear',
                                    transitionDelay: '5.5s',
                                }}>Find them at their next pop-up!</div>
                        </div>
                    </div>
                </div>
           </div>
          
               
            
            {
                showFeaturePopover === true && isMobile &&
                // controls
                <div className='vendor-feature-popover-controls-container'>
                    {
                        currentFeatureImageIndex > 0 &&
                        <button onClick={ () => setCurrentFeatureImageIndex(currentFeatureImageIndex - 1)}
                        className='vendor-feature-popover-controls-prev-btn'>
                            <img src='/assets/images/standard-icons/chevron-left.png'
                                className='vendor-feature-popover-controls-prev-img' />
                        </button>
                    }
                    {
                        currentFeatureImageIndex < (vendorFeatureImageCount-1) &&
                        <button onClick={ () => setCurrentFeatureImageIndex(currentFeatureImageIndex + 1)}
                        className='vendor-feature-popover-controls-next-btn'>
                            <img src='/assets/images/standard-icons/chevron-right.png'
                                className='vendor-feature-popover-controls-next-img' />
                        </button>
                    }
                    {
                        vendorFeatureImageCount > 1 && 
                        // pagination
                        <div className='pagination-container'
                            style={{
                                position: 'relative',
                                top: '92.5vh',
                                zIndex: '1000',
                            }}>
                            {
                                Array.from({length: vendorFeatureImageCount}).map((_, index) => {
                                    return <div className='pagination-circle' 
                                        key={index}
                                        style={{
                                            backgroundColor: currentFeatureImageIndex == index ? 'white' : 'transparent',
                                            transition: '0.25s ease-out'
                                        }}/>
                                })
                            }
                        </div>
                    }
                </div>
            }
        </div>
    )
}


export default VendorFeatureScreen;