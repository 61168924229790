import { db } from '../../firebase-config';
import { addDoc, collection } from 'firebase/firestore';
import NetworkUtility from '../../utilities/NetworkUtility.js';
import { Timestamp } from "@firebase/firestore";

class ShopItemRequestService {
    static shopItemRequestCollectionRef = collection(db, 'shopItemRequests');

    static createNewShopItemRequest = async (shopItem) => {
        const ip = await  NetworkUtility.getIPAddress();
        await addDoc(this.shopItemRequestCollectionRef,
            {...shopItem,
                'date': Timestamp.fromDate(new Date()),
                'ip': ip,
            });
    }
}

export default ShopItemRequestService;