import '../styles/widgets.css';

function PrimaryLongButton(props) {
    return (
        <button className='primary-long-button-container'
            onClick={ props.onClick }
            style = {{
             backgroundColor: props.color,
             width: props.width,
            }}>
            <div className='text-label-s primary-long-button-title'>
                {props.title}
            </div>
        </button>
    )
}

export default PrimaryLongButton;