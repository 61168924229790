import { useState } from 'react';
import './main-nav-bar.css';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import NavigationRoutes from '../../enums/Routes';
import { useSwipeable, config } from 'react-swipeable';

function MainNavbar() {
    const [showDrawer, setShowDrawer] = useState(false);
    const [currentRoute, setCurrentRoute] = useState('/');

    var isMobile = useMediaQuery({query: '(max-width: 600px)'})
    var isDesktop = useMediaQuery({query: '(min-width: 600px)'})


    function navigateTo(destinationRoute) {
        setCurrentRoute(destinationRoute);
        setShowDrawer(false);
        navigate(destinationRoute);
    }

    const navigate = useNavigate();

    const swipeHandlers = useSwipeable({
        onSwipedLeft: (_) => {setShowDrawer(false);},
    });
    

    return (
        <div className="main-navbar-root-container">
            { isMobile && 
                <div className="main-navbar-logo-company-container-mobile">
                    <button className='main-navbar-hamburger-menu-btn'
                        onClick={ () => setShowDrawer(true) }>
                        <img className='main-navbar-hamburger-menu-img' 
                        src='/assets/images/standard-icons/hamburger-menu-icon.png' />
                    </button>
                    <button className="main-navbar-company-title"
                        onClick={() => navigateTo(NavigationRoutes.homeScreen)}>
                            Forvilla
                    </button>
                    <div/>
                    <div/>
                </div> 
            }


            { isMobile && 
                <div className="main-navbar-drawer-menu-container"
                    style={{left: showDrawer ? '0' : '-100vw'}}
                    {...swipeHandlers}
                    >
                    <button onClick={ () => setShowDrawer(false) }>
                        <div className='main-navbar-drawer-menu-header-container'> 
                            <img className='main-navbar-drawer-menu-close-icon'
                                src='/assets/images/standard-icons/close-script-icon.png'/>
                            <div className='text-primary-ml main-navbar-drawer-menu-header-title'>Menu</div> 
                        </div>
                    </button>
                    <div style={{height: '4vh'}} />
                    <button onClick={ () => navigateTo(NavigationRoutes.homeScreen)}
                        className='main-navbar-menu-item-container main-navbar-menu-item-container-bg-a'>
                        <div className='text-label-xl main-navbar-menu-item-title'
                            style={{color: currentRoute == NavigationRoutes.homeScreen ? 'var(--color-secondary-3' : 'var(--color-text-secondary)'}}
                        >Home</div>
                    </button>
                    <div style={{height: '5vh'}} />
                    <div className='text-primary2-ml main-navbar-menu-section-header'>Find a pop-up by...</div>
                    <div style={{height: '2vh'}} />
                    <button onClick={ () => navigateTo(NavigationRoutes.eventFeatureScreen) }
                        className='main-navbar-menu-item-container main-navbar-menu-item-container-bg-b'>
                        <div className='text-label-l main-navbar-menu-item-title'
                            style={{color: currentRoute == NavigationRoutes.eventFeatureScreen ? 'var(--color-secondary-3' : 'var(--color-text-secondary)'}}
                        >Location or date</div>
                    </button>
                    <button onClick={ () => navigateTo(NavigationRoutes.vendorFeatureScreen)}
                        className='main-navbar-menu-item-container main-navbar-menu-item-container-bg-b'>
                        <div className='text-label-l main-navbar-menu-item-title'
                            style={{color: currentRoute == NavigationRoutes.vendorFeatureScreen ? 'var(--color-secondary-3' : 'var(--color-text-secondary)'}}
                        >Local small artist</div>
                    </button>
                    <button onClick={ () => navigateTo(NavigationRoutes.shopItemFeatureScreen)}
                        className='main-navbar-menu-item-container main-navbar-menu-item-container-bg-b'>
                        <div className='text-label-l main-navbar-menu-item-title'
                            style={{color: currentRoute == NavigationRoutes.shopItemFeatureScreen ? 'var(--color-secondary-3' : 'var(--color-text-secondary)'}}
                        >Handmade items</div>
                    </button>
                    <div style={{height: '2vh'}} />
                    <div className='main-navbar-contact-section-container'>
                        <div className='text-primary2-ml main-navbar-menu-section-header'>Connect</div>
                        <div style={{height: '2vh'}} />
                        <div className='main-navbar-contact-icons-container'>
                            <a href='https://www.instagram.com/forvilla_project' target='_blank'>
                                <img className='contact-icon-img' 
                                src='/assets/images/icons/ig-icon.png'/>
                            </a>
                            <a href='https://www.facebook.com/profile.php?id=100086501281875' target='_blank'>
                                <img className='contact-icon-img' 
                                src='/assets/images/icons/fb-icon.png'/>
                            </a>
                            <a href='https://apps.apple.com/us/app/forvilla/id6444273771' target='_blank'>
                                <img className='contact-icon-img' 
                                src='/assets/images/icons/apple-icon.png'/>
                            </a>
                            <a href='https://aqm55lzx2o4.typeform.com/to/AqHjOD3i' target='_blank'>
                                <img className='contact-icon-img' 
                                src='/assets/images/icons/android-icon.png'/>
                            </a>
                            <a href='mailto:forvilla@community.org'>
                                <img className='contact-icon-img' 
                                src='/assets/images/icons/email-icon.png'/>
                            </a>
                        </div>
                    </div>
                </div> 
            }



            {isDesktop &&
                <button className="main-navbar-logo-company-container"
                    onClick={() => navigateTo(NavigationRoutes.homeScreen)}>
                    <img className="main-navbar-logo-icon" src="assets/images/logos/logo-icon.png"/>
                    <div className="main-navbar-company-title">
                        Forvilla
                    </div>
                </button> 
            }
            {
            isDesktop && 
                <div className="main-navbar-tagline text-label-l">
                    For the village, from the village.
                </div>
            }
            <div/>
            <div/>
        </div>
       
    )
}

export default MainNavbar;