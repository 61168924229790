import './footer-section.css';
import { useMediaQuery } from 'react-responsive';

function FooterSection() {
    var isMobile = useMediaQuery({query: '(max-width: 600px)'});
    var isDesktop = useMediaQuery({query: '(min-width: 600px)'});

    return(
        <div className='footer-section-root-container'>
            <div>
                <div className='footer-section-mission-statement-container'>
                    <div className='text-primary-xl footer-section-mission-statement-header'>
                        For the village.
                    </div>
                    <div className='text-secondary-m footer-section-mission-statement-body'>
                        The Forvilla mission is to connect our community through in-person pop-ups and to encourage us all to come out to meet local artists and support their handmade items!
                    </div>
                </div>
                <div className='footer-section-get-in-touch-container'>
                    <div className='text-primary-ml footer-section-get-in-touch-header'>
                        Let's handmake it together.
                    </div>
                    <div className='text-secondary-m footer-section-get-in-touch-body'>
                        Want to join Forvilla? Reach out to us! 
                    </div>
                    <div className='footer-section-contact-list'>
                        <a className='footer-section-contact-list-item'
                            href='https://www.instagram.com/forvilla_project' target='_blank'>
                            <img className='footer-section-contact-icon-img' 
                            src='/assets/images/icons/ig-icon.png'/>
                            <div className='text-label-xl footer-section-contact-item-title'>@Forvilla_Project</div>
                        </a>
                        <a className='footer-section-contact-list-item'
                            href='https://www.facebook.com/profile.php?id=100086501281875' target='_blank'>
                            <img className='footer-section-contact-icon-img' 
                            src='/assets/images/icons/fb-icon.png'/>
                            <div className='text-label-xl footer-section-contact-item-title'>@Forvilla</div>
                        </a>
                        <a className='footer-section-contact-list-item'
                            href='mailto:forvilla@community.org'>
                            <img className='footer-section-contact-icon-img' 
                            src='/assets/images/icons/email-icon.png'/>
                            <div className='text-label-xl footer-section-contact-item-title'>Community@Forvilla.org</div>
                        </a>
                    </div>
                    {
                        isMobile &&
                        <div className='footer-section-download-icons-container-mobile'>
                             <a href='https://apps.apple.com/us/app/forvilla/id6444273771' target='_blank'>
                                <img src='/assets/images/icons/appstore-icon.png'
                                className='footer-section-apple-appstore-icon'/>
                            </a>
                            <a href='https://aqm55lzx2o4.typeform.com/to/AqHjOD3i' target='_blank'>
                                <img src='/assets/images/icons/playstore-icon.png'
                                className='footer-section-google-playstore-icon'/>
                            </a>
                            
                        </div>
                    }
                </div>
            </div>
            {
                isDesktop &&
                <div className='footer-section-download-app-container'>
                    <a className='footer-section-appstore-container'
                        href='https://apps.apple.com/us/app/forvilla/id6444273771' target='_blank'>
                        <img src='/assets/images/icons/appstore-icon.png'
                        className='footer-section-apple-appstore-icon'/>
                        <img src='/assets/images/general/appstore-phone.png'
                        className='footer-section-apple-appstore-screenshot'/>
                    </a>
                    <a className='footer-section-playstore-container'
                        href='https://aqm55lzx2o4.typeform.com/to/AqHjOD3i' target='_blank'>
                        <img src='/assets/images/icons/playstore-icon.png'
                        className='footer-section-google-playstore-icon'/>
                        <img src='/assets/images/general/playstore-phone.png'
                        className='footer-section-google-playstore-screenshot'/>
                    </a>
                </div>
            }
        </div>
    )
}

export default FooterSection;