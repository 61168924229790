import '../styles/widgets.css';

function StandardFormTextfield({label, value, width, height, onClick, onChange}) {

    return(
        <div className='standard-form-textfield-container'>
            <div className='text-label-m standard-form-input-label'>{label}</div>
            <input 
                className='text-secondary-m standard-form-input'
                style={{
                    width: width,
                    height: height,
                }}
                value={value}
                onClick={onClick}
                onChange={ (e) => onChange != null ? onChange(e.target.value) : {} }
            />
        </div>
    )
}

export default StandardFormTextfield;