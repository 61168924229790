import { useState, useEffect } from 'react';
import './home-screen.css'
import '../../widgets/PrimaryLongButton.jsx'
import PrimaryLongButton from '../../widgets/PrimaryLongButton.jsx';
import {styles} from '../../constants.js';
import { ActiveScreens } from '../../enums/Routes.js';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

function HomeScreen() {
    const [discoverAnimating, setDiscoverAnimating] = useState(false);

    const isMobile = useMediaQuery({query: '(max-width: 600px)'});

    const navigate = useNavigate();

    // useEffect(() => {
    //     if (discoverAnimating) {
    //         setTimeout(() => {
    //             navigate('/eventFeatureScreen');
    //             //setActiveScreen(ActiveScreens.eventFeatureScreen);
    //         }, 1200);
    //     }
    // }, [discoverAnimating])

    function handleDiscover() {
        setDiscoverAnimating(true);
        setTimeout(() => {
            navigate('/eventFeatureScreen');
        }, 1200);
    };

    function handleEventFeature() {
        setDiscoverAnimating(true);
        setTimeout(() => {
            navigate('/eventFeatureScreen');
        }, 1200);
    }

    function handleVendorFeature() {
        setDiscoverAnimating(true);
        setTimeout(() => {
            navigate('/vendorFeatureScreen');
        }, 1200);
    }

    function handleShopItemFeature() {
        setDiscoverAnimating(true);
        setTimeout(() => {
            navigate('/shopItemFeatureScreen');
        }, 1200);
    }
    
    return (
        <div className = "home-screen-root-container">
            <img className="home-screen-bg-image" 
                    src="assets/images/backgrounds/bg-day-1.png" />
            <div className="entry-point-container">
                <div className="entry-point-phone-container">
                    <img className="entry-point-phone-wood-img" 
                        src = "assets/images/material-backgrounds/bg-wood-main.png"
                        style={{opacity: !discoverAnimating ? '100%' : '0%'}}/>
                        
                    <img className="entry-point-phone-border-img" 
                        src = "assets/images/general/android-border-2.png" 
                        style={{scale: !discoverAnimating ? '100%' : '800%'}}
                        />
                    <div className='entry-point-content-container'
                        style={{opacity: !discoverAnimating ? '100%' : '0%'}} >
                        <div>
                            <img className='entry-point-logo-icon'
                            src= "assets/images/logos/logo-icon.png" />
                            <div className="entry-point-title">Forvilla</div>
                        </div>
                        <div className='entry-point-message-container'>
                            <div className='text-primary2-xxl entry-point-message'>pop-up</div>
                            <div className= "text-primary2-m entry-point-message">with the community!</div>
                        </div>
                        <div />
                        <PrimaryLongButton 
                            width={isMobile ? "250px" : "200px"} 
                            color={styles.colorSecondary2} 
                            title={'Discover'} 
                            onClick={() => handleDiscover() }/>
                        <div/>
                    </div>
                </div>
                <img className='entry-point-booth-img' src='assets/images/general/booth.png' 
                    style={{bottom: !discoverAnimating ? '0' : '-50vh'}}
                />
                <div className='text-label-l entry-point-booth-message'
                    style={{bottom: !discoverAnimating ? '5vh' : '-50vh'}}>
                        Finding our way to pop-ups to support local artists and their handmade items.
                </div>
                <div className='text-label-s entry-point-booth-message-on-board'
                    style={{bottom: !discoverAnimating ? '17.5vh' : '-50vh'}}>
                        Finding our way to pop-ups to support local artists and their handmade items.
                </div>
            </div>

            <div className='directory-menu-bar-container'
                style={{left: discoverAnimating ? '-30vw' : '0'}}>
                <div className='directory-menu-bar-title-container'>
                    <div className='text-primary2-m directory-menu-bar-title'>
                        Find a pop-up based on...
                    </div>
                </div>
                <div className='directory-menu-bar-items-container'>
                    <button className='directory-menu-bar-item-btn-container'
                        onClick={() => handleEventFeature()}>
                        <img className='directory-menu-bar-item-img'
                            src='/assets/images/general/directory-wooden-sign.png'/>
                        <div className='text-label-l directory-menu-bar-item'>
                            Location or date
                        </div>
                    </button>
                    <button className='directory-menu-bar-item-btn-container'
                        onClick={() => handleVendorFeature()}>
                        <img className='directory-menu-bar-item-img'
                            src='/assets/images/general/directory-wooden-sign-2.png'/>
                        <div className='text-label-l directory-menu-bar-item'>
                            Local artists
                        </div>
                    </button>
                    <button className='directory-menu-bar-item-btn-container'
                        onClick={() => handleShopItemFeature()}>
                        <img className='directory-menu-bar-item-img'
                            src='/assets/images/general/directory-wooden-sign.png'/>
                        <div className='text-label-l directory-menu-bar-item'>
                            Handmade items
                        </div>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default HomeScreen;