import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from '@firebase/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBJ0l3_mGz3Sq4DM7hEB1HiRW6ZOnGZHhA",
  authDomain: "forvilla-website.firebaseapp.com",
  projectId: "forvilla-website",
  storageBucket: "forvilla-website.appspot.com",
  messagingSenderId: "973303698453",
  appId: "1:973303698453:web:05a4c913f7b15ac9e86a1c",
  measurementId: "G-3N60T4RQX6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

export const db = getFirestore(app);